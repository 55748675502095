:root {
	--white-color:            #FFFFFF;
	--black-color:            #000000;
	--primary-color:          #B60000;
	--secondary-color:        #FFBB00;
	--error-color:            var(--primary-color);
	--success-color:          #28A745;
	--warning-color:          var(--secondary-color);
	--border-color:           #EFEFEF;
	--background-color:       #F0ECE3;
	--box-shadow:             0 0 15px 2px rgba(40,40,40,0.5);

	--primary-textcolor: #333333;
	--secondary-textcolor: #999999;
	--texthover-color: var(--primary-color);
	--textinfo-color: var(--warning-color);

	--form-field-width: 750px;
	--max-text-width: var(--form-field-width);

    --transition: 400ms ease-in-out all;

    --font-family-sourcesanspro: 'Source Sans Pro';
}

/*custom media queries*/

@custom-media --xxs-viewport (max-width: 450px);
/* Small devices (phones, 576px and up)*/
@custom-media --phoneUp-viewport (min-width: 576px);
@custom-media --min-sm-viewport (min-width: 576px);
/* Medium devices (tablets, 768px and up)*/
@custom-media --tabletUp-viewport (min-width: 768px);
@custom-media --min-md-viewport (min-width: 768px);
/* Large devices (tablet landscape, 992px and up)*/
@custom-media --tabletLandscapeUp-viewport (min-width: 992px);
@custom-media --min-lg-viewport (min-width: 992px);
/* Extra large devices (laptops, 1200px and up)*/
@custom-media --laptopUp-viewport (min-width: 1200px);
@custom-media --min-xl-viewport (min-width: 1200px);
/* Extra Extra large devices (large desktops, 1400px and up)*/
@custom-media --desktop-viewport (min-width: 1400px);
@custom-media --min-xxl-viewport (min-width: 1400px);

/* Extra small devices (portrait phones, less than 576px)*/
@custom-media --phone-viewport (max-width: 575px);
@custom-media --xs-viewport (max-width: 575px);
/* Small devices (landscape phones, less than 768px)*/
@custom-media --sm-viewport (max-width: 767px);
/* Medium devices (tablets, less than 992px)*/
@custom-media --md-viewport (max-width: 991px);
/* Large devices (latops, less than 1200px)*/
@custom-media --lg-viewport (max-width: 1199px);
/* XLarge devices (desktops, less than 1200px)*/
@custom-media --xl-viewport (max-width: 1399px);


@custom-media --only-sm-viewport (min-width: 576px) and (max-width: 767px);
@custom-media --only-md-viewport (min-width: 768px) and (max-width: 991px);
@custom-media --only-lg-viewport (min-width: 992px) and (max-width: 1199px);
@custom-media --only-xl-viewport (min-width: 1200px) and (max-width: 1399px);
