@import '../definitions.pcss';
.swiper-container,
.yellow-slider .swiper-slider
{
    position: relative;
    &.arrows
    {
        overflow: hidden;
        .swiper-slide
        {
            padding: 0 25px;
        }
        .swiper-button-prev,
        .swiper-button-next
        {
            left: 0;
            width: 25px;
            background: var(--secondary-color);
            height: 50px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            &::after
            {
                content: '';
                background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/chevron-left.svg', fill= var(--white-color)) no-repeat center;
                background-size: 16px !important;
                width: 100%;
                height: 100%;
            }
        }
        .swiper-button-next
        {
            left: auto;
            right: 0;
            &::after
            {
                background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/solid/chevron-right.svg', fill= var(--white-color)) no-repeat center;
            }
        }
    }
}
